.Header {
  background-color: #fff;
  position: fixed;
  min-height: 100px;
  max-height: 100px;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: 5px solid var(--brand-main-full);
}

.Header .nav-bar {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  margin: auto 5px;

/*

  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto 5px; */
}

.Header .nav-bar .brand {
  align-items: center;
  max-width: 40%;
  height: 100%;
  margin: 5px 10px;
}


.Header .nav-bar .brand img {
  display: block;
  align-self: flex-start;
  width: auto;
  height: 90px;
  margin: auto 5px;
}

.Header select {
  display: block;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  width: 100px;
  max-width: 100px;
  box-sizing: border-box;
  border: 1px solid #000;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5px;
}


.Header .hamburger {
  max-width:35px;
  max-height:35px;
  z-index: 10;
  display: inline-block;
  position: relative;
  margin-right: 10px
}


.Header .hamburger img{
  max-width:35px;
  max-height:35px;
}

.Header .nav-list {
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-width: 1100px;
  padding-right: 10px;
}


.Header .nav-list ul {
  list-style: none;
  position: absolute;
  background-color: var(--brand-main-full);;
  width: 100vw;
  height: 100vh;
  left: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
  transition: .5s ease left;
}

.Header .nav-list ul.active {
  left: 0%;
  margin-top: 0;
}

.Header .nav-list ul a {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  padding: 20px 11px;
  display: block;
}

.Header .nav-list ul li:hover a {
color: white;
}


@media only screen and (min-width: 875px) {

  .Header .nav-list ul {
  position: initial;
  display: block;
  height: auto;
  width: fit-content;
  background-color: transparent;
  }

  .Header .hamburger {
    display: none
  }

  .Header .nav-list ul li {
  display: inline-block;
  }

  .Header .nav-list ul li a {
  font-size: 16px;
  }

  .Header .nav-list ul li:hover a {
  color: var(--brand-main-full);
  }

  .Header .nav-list ul a:after {
  display: none;
  }
/* End header */
}


@media only screen and (max-width: 500px) {

  .Header {
    max-height: 80px;
    min-height: 80px;
  }

  .Header .nav-bar .brand {
    margin: 5px;
  }

  .Header .nav-bar .brand img {
    height: 50px;
  }
/* End header */
}
