.Services {
	max-width: 100%;
	margin: 0 auto;
  background-color: white;
  align-items: center;
  justify-content: center;
  color: black;
	position:relative;
}

.Services .service-top {
	max-width: 800px;
}

.Services .support-type{
	width: 100%;
	/* background-color: red; */
	align-items: center;
	/* justify-content: center; */
}

.Services .support-type h1{
	font-size: 24px;
	padding: 10px 0;
}

.support-type-inner {
	justify-content: space-around;
	width: 90%
}

.Services .support-item-container{
	align-items: center;
	justify-content: center;
	width: 45%;
	/* background-color: red; */
	padding: 10px;
	border: 2px solid var(--brand-main-full);
	border-radius: 7px
}

.Services .support-item-container h2{
	font-size: 18px;
	text-align: center;
}

.Services .service-title {
	justify-content: space-between;
	min-width: 90%;
	max-width: 95%;
	border-bottom: 1px solid var(--brand-seventy);
}
/*
.Services .serv-list {
	margin: 10px auto;
	max-width: 95%
} */

.Services .service-title .item-container{
	align-items: center;
	justify-content: center;
	padding: 5px;
	min-width: 25%;
	min-height: 20px;
	cursor: pointer;
}

.Services .service-title .item-container.active{
	border-bottom: 2px solid var(--brand-bright);
	box-shadow: 0px 2px var(--brand-bright-fty)
	/* background-color: var(--brand-fifty) */
}

.Services .service-title .item-container h2{
	font-size: 24px;
	text-align: center;
}

.Services .service-bottom {
	max-width: 85%;
	background-color: var(--brand-ten);
  flex-wrap: wrap;
	align-items: center;
	/* text-align: center; */
	justify-content: space-between;
	margin-top: 20px;
	padding: 10px 20px;
	border-radius:20px
}

.Services .service-bottom .service-item-heading {
	align-items: center;
	justify-content: center;
	align-self: stretch;
	border-bottom: 5px solid var(--brand-main-full);
	padding: 5px 0px
}

.Services .service-bottom .service-item-heading h2{
	font-size: 24px;
	padding-left:30px;
	color: black
}


.Services .service-bottom .service-item-content h2 {
	font-size: 20px;
	color: var(--brand-main-full);
	text-transform: uppercase;
  display: inline-block;
  text-align: center;
	padding: 15px 0px
}


.Services .service-bottom .service-item-content span{
	font-weight: 700;
}

.Services .service-bottom .service-item-content img{
	display: none;
}

.Services .service-bottom .service-item-content .service-item-content-right{
	display: flex;
	flex-direction: column;
	padding-top: 20px
}


.Services .service-bottom .service-item-content p {
	color: black;
	text-align: left;
	font-size: 16px;
	padding-bottom: 20px;
	font-weight: 400
}

.Services .service-bottom .service-item-content ul {
	color: black;
	text-align: left;
	font-size: 16px;
	padding-left: 20px;
	padding-bottom: 20px;
	margin: 0 30px;
}

.Services .service-bottom .service-item-content li {
	margin-top: 10px;
	text-align: left;
}


@media only screen and (max-width: 500px) {
	.Services .service-title .item-container h2{
		font-size: 12px;
	}

	.Services .service-bottom .service-item-heading h2{
		font-size: 20px;
	}

	.Services .service-bottom {
		max-width: 85%;
	}

	.Services .service-bottom .service-item-content h2 {
		font-size: 16px;
	}

	.Services .service-bottom .service-item-content p {
		font-size: 14px;
	}

	.Services .service-bottom .service-item-content ul {
		font-size: 14px;
		padding-left: 5px
	}

}

@media only screen and (max-width: 900px) and (min-width: 500px) {
  .Services .service-bottom .service-bottom {
    max-width: 80%;
	}
  .Services .service-bottom .service-item-content {
		margin: 2.5%;
    min-height: 200px;
		max-width: 90%
	}
  .Services .service-bottom .service-item-content h2{
		text-align: center;
	}
	.Services .service-title .item-container h2{
		font-size: 16px;
	}

	.Services .service-bottom {
		max-width: 80%;
	}

}

@media only screen and (min-width: 900px) {
  .Services .service-bottom .service-item {
		flex-basis: 45%;
		margin: 1.5%;
    max-width: 40%;
	}
}

/* End Services Section */
