:root {
  --brand-main-full: rgb(58,178,206);
  --brand-main-ninty: rgba(58,178,206, 0.9);
  --brand-seventy: rgba(58,178,206, 0.7);
  --brand-fifty: rgba(58,178,206, 0.5);
  --brand-twf:rgba(58,178,206, 0.25);
  --brand-ten:rgba(58,178,206, 0.1);
  --brand-five:rgba(58,178,206, 0.05);
  --brand-light:rgba(58,178,206, 0.05);  /*change this*/
  --brand-bright: rgb(219, 51, 29);
  --brand-bright-fty: rgb(219, 51, 29, 0.5);
  --brand-bright-twf: rgb(219, 51, 29, 0.25);
  --brand-bright-ten: rgba(205, 85, 58, 0.1); /*change this*/
  --white-dark: rgba(255,255,255,0.9);
  --white-light: rgba(255,255,255,0.02);
  --text-white: rgb(250, 250, 250);
  --font-main: 'Montserrat', sans-serif;

}



.App {
  /* text-align: center; */
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	font-size: 10px;
	/* font-family: 'Montserrat', sans-serif; */
	scroll-behavior: smooth;
}
 body{
   overflow-x: hidden;
 }


a {
	text-decoration: none;
}


p {
	color: black;
	font-size: 1.4rem;
	margin-top: 5px;
	line-height: 2.5rem;
	font-weight: 300;
	letter-spacing: .05rem;
}

.section-title {
	font-size: calc(16px + 2vmax);
	font-weight: 300;
	color: black;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: .2rem;
	text-align: center;
  padding: 0px 20px
}
.section-title span {
	color: var(--brand-main-full);
  font-weight: bold;
}

.cta {
	display: inline-block;
	padding: 10px 20px;
	color: black;
	background-color: var(--brand-main-full);
	border: 2px solid var(--brand-main-full);
	font-size: 1.5rem;
	text-transform: uppercase;
	letter-spacing: .1rem;
	margin: 20px;
	/* transition: .3s ease;
	transition-property: background-color, color, border-color; */
  border-radius: 5px;
}

a.disabled-btn {
  pointer-events: none;
  cursor: default;
  color: grey;
}

.cta:hover {
	color: white;
	background-color: var(--brand-bright);
  border-color:transparent
}

.container {
	min-height: 70vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  padding: 40px 0px
}

.dummy-div {
	height: 100px;
	width: 100%;
	background-color: var(--brand-main-ten);
	position:absolute;
	z-index: 1;
	align-self: flex-end;
	bottom: 0
}


.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}




.Footer {
  max-height: 70px;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-top: 10px solid var(--brand-main-full)
}


.Footer .footer-left{
  justify-content: center;
  align-self: center;
  /* margin-left: 30px; */
  margin: auto 10px
}

.Footer .footer-left img{
  height: 50px;
}

.Footer .footer-middle {
  text-align: center;
  justify-content: center;
	max-width: 200px
}

.Footer p{
  font-size: 12px;
  line-height: normal;
}


.Footer .footer-right{
  justify-content:flex-end;
  margin: auto 10px;
  max-width: 25%
}

.Footer .footer-right p{
  text-align: center;
}

.pretty-link {
  text-decoration: none;
  font-weight: 600;
  font-style:italic;
}


@media only screen and (max-width: 760px) {

  .dummy-div {
  	height: 80px;
  }

  .Footer .footer-middle {
    text-align: center;
    justify-content: center;
  	max-width: 150px
  }

  /* .Header .nav-bar .brand img{
    height: 70px;
  } */



  .Footer p{
    font-size: 10px;
  }
}
