/* Projects section */
 .Portfolio {
	max-width: 100%;
	margin: 0 auto;
  background-color: var(--brand-ten);
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

/* .Portfolio  .projects-header:first-child h1 {
	margin-bottom: 10px;
} */

.Portfolio  .projects-header h1 {
	margin-bottom: 30px;
}

.Clients {
  background-color: white;
  min-height: auto;
  position: relative;
}

.Clients .clients {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.Clients .client-logo-cont {
  width: 45%;
  align-items:center;
  justify-content: center;
}

.Clients .client-logo-cont img{
  width: 65%;
}

.Clients .client-logo-cont:nth-child(2) img{
  width: 95%;
}

.Portfolio  .all-projects {
	/* display: flex; */
  /* flex-wrap: wrap; */
	/* align-items: center; */
	/* justify-content: center; */
	/* flex-direction: row; */
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.Portfolio .project-item {
  /* flex: 1; */
	align-items: center;
  align-self: stretch;
	margin: 10px auto;
	overflow: hidden;
	border-radius: 10px;
  background-color: var(--brand-twf);
  /* background-image: linear-gradient(60deg, #3ab1cd 0%, #3ab1cd 30%); */
  min-height: 10vh;
  display: flex;
  min-width:85%;
  max-width:90%;
  color: white;
  float: left;
  padding: 20px;
}

/* .Portfolio .project-item:nth-child(1) {
  margin-right: 20px;
} */

.Portfolio  .project-info {
	align-items: flex-start;
  justify-content: space-around;
	color: black;
  align-self: stretch;
  overflow: hidden;
	position: relative;
  /* height:100%; */
  margin: 0px 15px
}


.Portfolio .project-info h1 {
	font-size: 16px;;
	font-weight: 600;
  text-align: left;
  padding: 10px 0;
}
.Portfolio .project-info h2 {
	font-size:14px;
	font-weight: 500;
	margin: 10px 0px;
  padding: 10px 0;
  text-align: left;
}
.Portfolio .project-info a {
  text-decoration: none;
  color: black;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  background-color:white
}

.Portfolio .project-info a:hover {
  color: white;
  border: 1px solid var(--brand-main-full);
  background-color: var(--brand-main-full);
}

.Portfolio .project-info h5{
  padding-top: 10px;
  font-size:12px;
  text-align: left;
}

/* .Portfolio .project-right{
	overflow: hidden;
	position: relative;
  height:100%;
  margin: 20px
} */

.Portfolio .more-papers {
  align-self: stretch;
  display: flex;
  padding: 20px;
  justify-content: center;
  z-index: 10
}

.Portfolio .more-papers a {
  text-decoration: none;
  color: black;
  border: 1px solid black;
  padding: 10px 10px;
  min-width: 25%;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color:white;
  text-align: center;
}

.Portfolio .more-papers a:hover {
  color: white;
  border: 1px solid var(--brand-main-full);
  background-color: var(--brand-main-full);
}

@media only screen and (max-width: 650px) {

  .Portfolio  .all-projects {
    width: 90%;
  }

  .Portfolio  .all-projects {
  	display: flex;
  	flex-direction: column;
    width: 95%;
    /* align-items: center; */
  	/* justify-content: center; */
  }

  .Portfolio .project-item {
      /* min-height: 350px; */
  }


}
/* End Projects section */
