.Contact {
	margin: 0 auto;
  background-color: var(--brand-ten);
  min-height: 40vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmax);
  color: white;
}

.Contact  .container-out {
	width: 80%;
  padding: 20px 0; /* adds padding at the top and bottom of the outer container
  /* margin: 0 auto; /*centers the outer container in the middle of the body */

}

.Contact .container-in {
	display: flex;
	justify-content: center;
	align-content: center;
  padding: 30px 0; /* adds padding at the top and bottom of the inner container */
  width: 95%; /* width of inner container equals 95% of the outer container */
  margin: 0 auto; /*centers the outer container in the middle of the outer container */
  background: var(--brand-seventy); /* gives the inner container a background color */
  border-radius: 10px;
}

.Contact .contact-form {
  width: 80%; /* width of inner container equals 85% of the inner container */
  margin: 0 auto; /*centers the outer container in the middle of the inner container */
	text-align: left;
}

.Contact .contact-form p {
  margin: 0; /* removes all margins from the p tags */
  margin: 10px 0; /* add 5px margin at the top and bottom of the p tags */
  color: black; /* changes the text color of the p tag to white */
	font-weight: bold;
}

.Contact .contact-form input {
  border: none; /* removes the border on the input */
  width: 100%; /* makes the input width same as the contact-form div*/
  margin-bottom: 15px; /* adds margin at the bottom of the input */
  padding: 10px; /* adds 10px padding to all sides of the input */
  box-sizing: border-box; /* prevents the input from overflowing out of the parent div */
  border-radius: 5px; /* curves the edges of the input by 3px */
}

.Contact .contact-form textarea {

	border: none; /* removes the border on the input */
  width: 100%; /* makes the input width same as the contact-form div*/
  margin-bottom: 15px; /* adds margin at the bottom of the input */
  padding: 10px; /* adds 10px padding to all sides of the input */
  box-sizing: border-box; /* prevents the input from overflowing out of the parent div */
  border-radius: 5px; /* curves the edges of the input by 3px */
	min-height: 120px; /* gives the text-area a minimum height of 120px */
  resize: vertical; /* limits resizing to vertical resizing */
}

.Contact .contact-form button {
  border: none; /* removes the border on the button */
  width: 150px;
  height: 40px;
  padding: 7px; /*  adds px padding to all sides of the button */
  background: var(--brand-bright); /* gives the button a background color */
  color: white; /* changes the text color of the button to white */
  border-radius: 20px; /* curves the edges of the button by 4px */
  cursor: pointer; /* changes the mouse pointer when you hover over the button */
  font-weight: 600; /* boldens the text of the button */
  font-family: var(--font-main);
  letter-spacing:1px;
	font-size: 14px;
}

.Contact .contact-form button:hover {
	color:black;
	background:white;
	border:2px solid var(--brand-bright)
}
