
/* About Section */
.About {
	margin: 0 auto;
	background-image: url("../img/CJ-background.jpg");
  min-height: 70vh;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmax); */
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 1;
  margin: 0 auto;
}

.About::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	opacity: .7;
	z-index: -1;
}

.About .section-title {
	color: black
}

.About .about-inside {
	width: 90%;
}

.About .about-inside h2 {
	font-size: 22px;
	font-weight: 600;
	letter-spacing: .2rem;
	margin-bottom: 10px;
	text-align: center;
	color: black
}
.About .about-inside p {
	margin-bottom: 20px;
  text-align: left;
	color: black;
	font-weight: 400;
}

.About ul {
	font-size: 18px;
	padding-left: 20px;
}

.About li {
	margin-top: 20px;
}
2
.About .col-left .about-img {
	height: 100%;
	width: 100%;
	position: relative;
	border: 10px solid white;
  z-index: 1;
}
.About .col-left .about-img::after {
	content: '';
	position: absolute;
	left: -33px;
	top: 19px;
	height: 98%;
	width: 98%;
	border: 7px solid var(--brand-main-full);
	z-index: -1;
	border-radius: 15px
}

.About .col-left .about-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 15px
}

.About .more-profile {
  align-self: stretch;
  display: flex;
  padding: 20px;
  justify-content: center;
  z-index: 10
}

.About .more-profile a {
  text-decoration: none;
  color: white;
  border: 2px solid var(--brand-main-full);
  padding: 10px 10px;
  min-width: 25%;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color:var(--brand-main-full);
  text-align: center;
}

.About .more-profile a:hover {
  color: black;
  background-color: white;
}


/* End About Section */


@media only screen and (min-width: 800px) {

	/* About */


	.About .col-left {
		width: 600px;
		height: 400px;
		padding-left: 60px;
	}
	.About .col-left .about-img::after {
		left: -45px;
		top: 34px;
		height: 98%;
		width: 98%;
		border: 10px solid var(--brand-main-full);
	}

	.About .about-inside {
		padding: 10px;
	}
	.About .about-inside h1 {
		text-align: center;
	}
	/* End About */

}


@media only screen and (max-width: 800px) {

	/* About */
	.About {
		flex-direction: column;
		padding: 0px 5px;
	}

	.About .col-left {
		width: 600px;
		height: 400px;
		padding-left: 20px;
	}

	.About .about-inside {
		text-align: center;
		padding-top: 40px;
	}

	.About .about-inside h1 {
		text-align: center;
		/* font-size: calc(15px + 2vmax); */
	}

	.About .about-inside h2 {
		text-align: center;
		font-size: 18px
	}

	.About .about-inside p {
	  /* text-align: center; */
	}
	/* End About */
}
