.Home_Main {
	position: relative;
}

/* Home Section */


.Home {
  background-image: url("../img/eye.jpg");
	background-size: cover;
	background-position:center;
	max-height: 60vh !important;
	position: relative;
	z-index: 1;
  margin: 0 auto;
	margin-top: 100px;
	justify-content: flex-start;
}

.Home::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	opacity: .7;
	z-index: -1;
}

.Home .intro {
  background-color: var(--white-light);
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}


.Home .intro h1 b {
	color: transparent;
	animation: text_reveal_name .5s ease forwards;
	font-weight: 700;
	padding-right: 8px
}

.Home .intro h1 b:nth-child(1) {
	animation-delay: 1s;
}

.Home .intro h1:nth-child(2) b {
	animation-delay: 2s;
}

.Home h1 {
	display: block;
	width: fit-content;
	font-size: 30px;
	position: relative;
	color: transparent;
	animation: text_reveal .5s ease forwards;
	animation-delay: 1s;
  padding-top: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}
.Home h1:nth-child(1) {
	animation-delay: 1s;
}
.Home h1:nth-child(2) {
	animation-delay: 2s;
}
.Home h1:nth-child(3) {
	animation: text_reveal_name .5s ease forwards;
	animation-delay: 3s;
	font-weight: bolder;
	font-size: 30px;
	font-weight: 700;
}
.Home h1 span {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: var(--brand-main-full);
	animation: text_reveal_box 1s ease;
	animation-delay: .5s;
}
.Home h1:nth-child(1) span {
	animation-delay: .5s;
}
.Home h1:nth-child(2) span {
	animation-delay: 1.5s;
}
.Home h1:nth-child(3) span {
	animation-delay: 2.5s;
}

.Home .home-bttns {
	display: inline-block;
	justify-content: center;
}

/* End Home Section */

/* Start Home 2 Section */

.Home2 {
	text-align: center;
	margin: 0 auto;
  background-color: var(--brand-ten);
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  color: black;
}


.Home2 .col-left {
	width: 300px;
	height: 350px;
	min-width: 250px;
	max-width: 360px;
	margin-left: 50px;
	margin-top: 30px
}
.Home2 .col-right {
	width: 70%;
}

.Home2 .col-right p {
	padding-bottom: 40px;
  text-align: left;
	font-style: italic;
	letter-spacing: 2px;
	font-size: 20px
}

.Home2 .col-right h2 {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 2px;
	margin-bottom: 10px;
	text-align: right;
}


.Home2 .col-left .home2-img {
	height: 100%;
	width: 100%;
	position: relative;
  z-index: 1;
}
.Home2 .col-left .home2-img::after {
	content: '';
	position: absolute;
	left: -33px;
	top: 19px;
	height: 98%;
	width: 98%;
	border: 7px solid var(--brand-main-full);
	z-index: -1;
	border-radius: 15px
}

.Home2 .col-left .home2-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 15px
}



/* End Home2 Section */



@keyframes text_reveal_box {
	50% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}
@keyframes text_reveal {
	100% {
		color: black;
	}
}
@keyframes text_reveal_name {
	100% {
		color: var(--brand-main-full);
		font-weight: 500;
	}
}


@media only screen and (max-width: 400px) {

	/* Home */
	.Home {
		padding-top: 50px;
	}

	.Home .intro h1 {
		font-size: calc(10px + 2vmax)
	}

	/* End Home */
}

@media only screen and (max-width: 760px) {

	/* Home */
	.Home {
		margin-top: 80px;
	}
	/* End Home */
}


@media only screen and (min-width: 800px) {

	/* Home2 */

	.Home2 .col-left {
		width: 600px;
		height: 400px;
		padding-left: 60px;
	}
	.Home2 .col-left .home2-img::after {
		left: -45px;
		top: 34px;
		height: 98%;
		width: 98%;
		border: 10px solid var(--brand-main-full);
	}

	.Home2 .col-right {
		text-align: left;
		padding: 70px;
	}
	.Home2 .col-right h1 {
		text-align: left;
	}
	/* End About */

}


@media only screen and (max-width: 800px) {

	/* About */
	.Home2 {
		flex-direction: column;
	}

	.Home2 .col-left {
		max-width: 500px;
		max-height: 400px;
		margin: 10px 50px;
	}

	.Home2 .col-right {
		text-align: center;
		padding-top: 40px;
	}


	.Home2 .col-right h2 {
		text-align: right;
		padding-bottom: 10px
	}

	.Home2 .col-right p {
	  text-align: center;
	}
	/* End About */
}
